import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
    name: "AppIconTriangle",
    inheritAttrs: false,
    props: {
        rotate: { type: Number, default: 0 },
    },
    setup(props) {
        const styles = computed(() => {
            return {
                transform: `rotate(${props.rotate}deg)`,
            };
        });
        return {
            styles,
        };
    },
});
