import { defineComponent, computed } from "@vue/composition-api";
import { createPopper } from "@popperjs/core";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import useLockBody from "../../composable/lock-body";
const EMPTY = "--EMPTY--";
export default defineComponent({
    name: "AppSelect",
    inheritAttrs: false,
    components: {
        VSelect,
    },
    props: {
        required: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        value: { type: [String, Number], default: "" },
        options: {
            type: Array,
            required: true,
        },
        initialOptionTitle: { type: String, default: EMPTY },
        initialOptionValue: { type: [String, Number], default: EMPTY },
    },
    emits: {
        change: (value) => typeof value === "string",
    },
    setup(props, { emit }) {
        const { lockBody, unlockBody } = useLockBody();
        let popper = null;
        const optionsWithInitial = computed(() => {
            if (props.initialOptionTitle === EMPTY && props.initialOptionValue === EMPTY) {
                return props.options;
            }
            if (props.initialOptionValue !== EMPTY && props.initialOptionTitle !== EMPTY) {
                return [
                    {
                        title: props.initialOptionTitle,
                        value: props.initialOptionValue,
                    },
                    ...props.options,
                ];
            }
            throw new Error("INITIAL VALUE AND TITLE SHOULD BE BOTH PROVIDED");
        });
        const empty = computed(() => {
            return props.required && (props.value === "" || Number.isNaN(props.value) || props.value === 0);
        });
        const selectedOption = computed(() => {
            const selectedOption = optionsWithInitial.value.find(o => o.value === props.value);
            return selectedOption || { title: "", value: "" };
        });
        function change(option) {
            if (option.value === "")
                return;
            emit("change", option.value);
            if (popper !== null)
                popper.update();
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function withPopper(dropdownList, component, { width }) {
            dropdownList.style.maxWidth = width;
            popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: "bottom-start",
                modifiers: [{ name: "offset", options: { offset: [0, -1] } }],
            });
            return () => popper && popper.destroy();
        }
        const toggleBody = (type) => {
            if (props.options.length < 10)
                return;
            if (type === "lock")
                lockBody();
            if (type === "unlock")
                unlockBody();
        };
        return {
            optionsWithInitial,
            empty,
            selectedOption,
            change,
            withPopper,
            toggleBody,
        };
    },
});
