import { computed, defineComponent } from "@vue/composition-api";
import AppIconLoader from "./icons/Loader.vue";
export default defineComponent({
    name: "AppLoader",
    inheritAttrs: false,
    components: {
        AppIconLoader,
    },
    props: {
        iconLeft: { type: Boolean, default: false },
        iconRight: { type: Boolean, default: false },
        asBlock: { type: Boolean, default: false },
        textCenter: { type: Boolean, default: false },
        iconScale: { type: String, default: "1" },
    },
    setup(props) {
        const styles = computed(() => ({
            display: props.asBlock ? "flex" : null,
            "justify-content": props.textCenter ? "center" : null,
        }));
        const iconStyles = computed(() => ({
            order: props.iconLeft ? 1 : props.iconRight ? 3 : 0,
            width: `calc(1em * ${props.iconScale})`,
            height: `calc(1em * ${props.iconScale})`,
        }));
        return {
            styles,
            iconStyles,
        };
    },
});
