function lockBody() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.querySelector("body").style.overflowY = "hidden";
}
function unlockBody() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.querySelector("body").style.overflowY = "auto";
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default function useLockBody() {
    return {
        lockBody,
        unlockBody,
    };
}
