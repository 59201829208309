import { computed, defineComponent } from "@vue/composition-api";
import { useEmitter } from "../../composable/emit";
export default defineComponent({
    name: "AppRadioGroup",
    props: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        list: { type: Array, required: true },
        value: { type: [String, Number], required: true },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        keyField: { type: String, required: true },
        titleField: { type: String, default: "" },
        radioClass: { type: String, default: "" },
        radioSize: { type: Number, default: 16 },
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        if (!props.list.every(i => props.keyField in i)) {
            throw new Error("SOME ITEM DOES NOT HAVE KEY FIELD");
        }
        const empty = computed(() => {
            return props.required && props.list.every(i => i[props.keyField] !== props.value);
        });
        const selectItem = (item) => {
            emit("change", item);
        };
        return {
            empty,
            selectItem,
        };
    },
});
