import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppText",
    inheritAttrs: false,
    props: {
        value: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        rows: { type: Number, default: 1 },
        resizable: { type: Boolean, default: false },
    },
    emits: {
        input: (str) => typeof str === "string",
        change: (str) => typeof str === "string",
    },
    setup(props, { emit }) {
        const empty = computed(() => {
            if (props.disabled)
                return false;
            return props.required && props.value === "";
        });
        const styles = computed(() => ({
            resize: props.rows === 1 && !props.resizable ? "none" : "vertical",
        }));
        const blur = (event) => {
            const target = event.target;
            if (props.rows === 1)
                target.blur();
            if (!event.shiftKey)
                target.blur();
        };
        const focus = (target) => {
            target.select();
        };
        const input = (value) => {
            emit("input", value);
        };
        const change = (value) => {
            emit("change", value);
        };
        return {
            empty,
            styles,
            blur,
            focus,
            input,
            change,
        };
    },
});
