import { defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppSuspense",
    inheritAttrs: false,
    props: {
        loading: { type: Boolean, required: true },
        // TODO: Rename to hideLoader with default false
        showLoader: { type: Boolean, default: true },
    },
});
