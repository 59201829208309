<template>
  <TrevoreyreAutocomplete
    ref="autocompleteSearch"
    :search="internalSearch"
    :debounce-time="debounce"
    :disabled="disabled"
    :readonly="readonly"
    :tabindex="readonly ? -1 : 0"
    v-bind="$attrs"
    @submit="handleSubmit"
    @blur="clearSearch"
  >
    <template #result="{result, props}">
      <li v-bind="props">
        <slot :result="result" />
      </li>
    </template>
  </TrevoreyreAutocomplete>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

export default {
  name: "AppAutocomplete",
  inheritAttrs: false,

  components: {
    TrevoreyreAutocomplete: Autocomplete,
  },

  props: {
    search: {
      /** @type import("vue").PropType<<T=unknown>(input: string) => Promise<T[]>> */
      type: Function,
      required: true,
    },
    clearOnSubmit: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    debounce: { type: Number, default: 500 },
    minSearchQueryLength: { type: Number, default: 2 },
  },

  methods: {
    /** @param input {string} */
    internalSearch (input) {
      return new Promise((resolve) => {
        if (input.length < this.minSearchQueryLength) return resolve([]);
        this.search(input).then(resolve);
      });
    },

    /** @param result {undefined | unknown} */
    handleSubmit (result) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("submit", result);

      if (result === undefined) return;

      if (this.clearOnSubmit) {
        this.$nextTick(this.clearSearch);
      }
    },

    clearSearch () {
      if (this.$refs.autocompleteSearch) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.autocompleteSearch.setValue();
      }
    },
  },
};
</script>

<style lang="scss">
.autocomplete-input {
  border-radius: var(--app-border-radius);
  border: 1px solid var(--app-color-bold-strong);
  background-color: var(--app-color-none);
  padding: 4px 5px 4px 48px;

  &:focus {
    border-color: var(--app-color-bold-strong);
    background-color: var(--app-color-none);
    outline: auto;
    box-shadow: none;
  }

  &:disabled {
    cursor: no-drop;
    border-color: var(--app-color-disabled-strong);
    background-color: var(--app-color-disabled-weak);
  }

  &:read-only {
    outline: none;
  }
}
</style>
