
<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    :style="styles"
  >
    <g fill="none">
      <path
        d="M12 3h7v8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 17v4H5V3h7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 3.5v3h-5v-3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14h-8.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 14l3 3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14l3-3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AppIconClipboardArrowOutline",
  props: {
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    styles () {
      if (!this.left && !this.right) throw new Error("CHOOSE DIRECTION");

      return {
        transform: this.right ? "scaleX(-1)" : null,
      };
    },
  },
};
</script>
