// original source from package: @sweet-monads/either, author: Artem Kobzar
// https://github.com/JSMonk/sweet-monads/tree/master/either
var EitherType;
(function (EitherType) {
    EitherType["Left"] = "Left";
    EitherType["Right"] = "Right";
})(EitherType || (EitherType = {}));
class EitherConstructor {
    // eslint-disable-next-line no-useless-constructor
    constructor(type, value) {
        this.type = type;
        this.value = value;
    }
    static success(v) {
        return new EitherConstructor(EitherType.Right, v);
    }
    static fail(v) {
        return new EitherConstructor(EitherType.Left, v);
    }
    isFail() {
        return this.type === EitherType.Left;
    }
    isOk() {
        return this.type === EitherType.Right;
    }
}
export const { fail, success } = EitherConstructor;
export const isEither = (value) => value instanceof EitherConstructor;
