import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
    name: "AppRadio",
    inheritAttrs: false,
    props: {
        /** Its read only value */
        model: { type: [String, Number], required: true },
        /** Current value of variable */
        value: { type: [String, Number], required: true },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        title: { type: String, default: "" },
        size: { type: Number, default: 20 },
        empty: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
    },
    emits: {
        change: (model) => typeof model === "string",
    },
    setup(props, { emit }) {
        const selected = computed(() => {
            return props.model === props.value;
        });
        function select() {
            if (props.readonly || props.disabled)
                return;
            emit("change", props.model);
        }
        return {
            selected,
            select,
        };
    },
});
