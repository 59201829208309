// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(key, classOfInstance) {
    var _a;
    try {
        const value = (_a = localStorage.getItem(key)) !== null && _a !== void 0 ? _a : "";
        const object = JSON.parse(value);
        if (classOfInstance !== undefined) {
            Object.setPrototypeOf(object, classOfInstance.prototype);
        }
        return object;
    }
    catch (e) {
        return null;
    }
}
function set(key, value) {
    const strValue = JSON.stringify(value);
    localStorage.setItem(key, strValue);
}
function remove(key) {
    localStorage.removeItem(key);
}
export default {
    get,
    set,
    remove,
};
