import { isNumber, isString } from "@/utils/check-types-runtime";
const wrapText = (text) => {
    return `
  <START--------------------------------------->
  ${text}
  <END----------------------------------------->
  `;
};
class ApiBaseError extends Error {
    constructor(name, response) {
        super(name);
        this.name = name;
        this.response = response;
    }
    get responseString() {
        const obj = {};
        for (const key in this.response) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const value = this.response[key];
            if (isString(value) || isNumber(value)) {
                obj[key] = value;
            }
        }
        obj.headers = Object.fromEntries(this.response.headers.entries());
        return JSON.stringify(obj, null, 2);
    }
    async getResponseData() {
        try {
            const data = await this.response.clone().json();
            return JSON.stringify(data, null, 2);
        }
        catch (e) {
            if (e.message.includes("Unexpected token <") === true)
                return "RESPONSE DATA IS A TEXT";
            else
                throw e;
        }
    }
    async getResponseText() {
        var _a;
        const text = await this.response.clone().text();
        if (!text.includes("!DOCTYPE html"))
            return text;
        const div = document.createElement("div");
        div.innerHTML = text;
        div.querySelectorAll("style").forEach(e => e.remove());
        div.querySelectorAll("script").forEach(e => e.remove());
        return (_a = div.textContent) !== null && _a !== void 0 ? _a : "";
    }
    getResponseString() {
        var _a;
        return `
    NAME: ${this.name}
    STATUS CODE: ${this.response.status}
    ------------------------------
    STACK:
    ${(_a = this.stack) !== null && _a !== void 0 ? _a : "NO STACK EXIST"}
    ------------------------------
    RESPONSE
    ${this.responseString}
    `;
    }
    async print(printString = true) {
        const string = this.getResponseString();
        const text = await this.getResponseText();
        const data = await this.getResponseData();
        if (printString)
            console.error(wrapText(string));
        console.error(wrapText(`RESPONSE TEXT: ${text}`));
        console.error(wrapText(`RESPONSE DATA: ${data}`));
    }
    toString() {
        return this.getResponseString();
    }
}
export class ApiServerError extends ApiBaseError {
    constructor(response) {
        super("SERVER_ERROR", response);
    }
}
export class ApiValidationError extends ApiBaseError {
    constructor(response) {
        super("VALIDATION_ERROR", response);
    }
    getErrors() {
        return this.response.clone().json().then(json => json.errors);
    }
    async getObjectErrors() {
        const errors = await this.getErrors();
        const transformedErrors = errors.reduce((accumulator, errorField) => ({
            ...accumulator,
            [errorField.field]: errorField.messages,
        }), {});
        return transformedErrors;
    }
}
export class ApiAuthorizationError extends ApiBaseError {
    constructor(response) {
        super("AUTHORIZATION_ERROR", response);
    }
}
export class ApiNotFoundError extends ApiBaseError {
    constructor(response) {
        super("NOT_FOUND_ERROR", response);
    }
}
export class ApiUnknownError extends Error {
    constructor(error) {
        super("API_UNKNOWN_ERROR");
        this.errorInstance = error instanceof Error ? error : new Error("API_EMPTY_ERROR");
    }
    getError() {
        return this.errorInstance;
    }
}
