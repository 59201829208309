import { request } from "@/services/api";
import { request as deprecatedRequest } from "@/api/request";
import storage from "@/utils/storage";
export const initializeAccessToken = () => {
    const token = storage.get("user-access-token");
    if (token !== null) {
        request.setAuthorizationHeader(token);
        deprecatedRequest.setAuthorizationHeader(token);
    }
};
export const setAccessToken = (token) => {
    storage.set("user-access-token", token);
    request.setAuthorizationHeader(token);
    deprecatedRequest.setAuthorizationHeader(token);
};
export const clearAccessToken = () => {
    storage.remove("user-access-token");
    request.deleteAuthorizationHeader();
    deprecatedRequest.deleteAuthorizationHeader();
};
