/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const management = {
  state: {
    notifications: [],
    mrus: [],
    mru: {},
    groups: [],
    group: {},
    locations: [],
    location: {},
    searched_id: undefined,
  },
  mutations: {
    SET_NOTES (state, payload) {
      state.notifications = payload;
    },
    SET_MRUS (state, payload) {
      state.mrus = payload;
    },
    SET_MRU (state, payload) {
      state.mru = payload;
    },
    SET_GROUPS (state, payload) {
      state.groups = payload;
    },
    SET_GROUP (state, payload) {
      state.group = payload;
    },
    SET_LOCATION (state, payload) {
      state.location = payload;
    },
    SEARCHED (state, payload) {
      state.searched_id = payload;
    },
  },
  actions: {
    clearNotes ({ commit }) {
      commit("SET_NOTES", []);
    },
    addNotes ({ commit }, payload) {
      commit("SET_NOTES", payload);
    },
    setMrus ({ commit }, payload) {
      commit("SET_MRUS", payload);
    },
    setMru ({ commit }, payload) {
      commit("SET_MRU", payload);
    },
    setGroups ({ commit }, payload) {
      commit("SET_GROUPS", payload);
    },
    setGroup ({ commit }, payload) {
      commit("SET_GROUP", payload);
    },
    setLocation ({ commit }, payload) {
      commit("SET_LOCATION", payload);
    },
    searched ({ commit }, payload) {
      commit("SEARCHED", payload);
    },
  },
};
