/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

export const map = {
  state: {
    selectedStaticTypeIds: [],
    staticObjects: [],
    appliances_tails: [],
    appliances_tails_points: [],
    points_on_map: [],
    date_range: [],
    map_type: "osm",
    wether_info: {},
    selected_unit_info: {},
    departments: [],
    traking_appliances: [],
    hovered_altitude: {},
    lst_point_info_id: "",
    users: [],
    map_types: [
      {
        key: "osm",
        name: "OSM",
        val: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      },
      {
        key: "landscape",
        name: "Рельєф",
        val: "https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=3836421ef7b94540bb2bec7ee6b35486",
      },
      {
        key: "outdoors",
        name: "outdoors",
        val: "https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=3836421ef7b94540bb2bec7ee6b35486",
      },
      {
        key: "satellite",
        name: "Супутник",
        val: "hybrid",
      },
    ],
    appliances: [],
    mrsus: [],
    // DEBUG: center near working rescuer
    // map_center: [48.026901803890695, 24.63272094726563],
    map_center: [48.91754, 24.67495],
    daterange: "",
  },

  mutations: {
    SET_STATIC (state, payload) {
      const type_id = payload[0].type_id;
      const is_exist = state.staticObjects.find(x => {
        return x.type_id === type_id;
      });
      if (is_exist) {
        state.staticObjects = state.staticObjects.filter(x => {
          return x.type_id !== type_id;
        });
      }
      else {
        state.staticObjects = _.concat(state.staticObjects, payload);
      }
    },
    SET_DATERANGE (state, payload) {
      // DEBUG: custom date range
      // payload = "2020/01/20 10:00:00,2020/11/20 10:10:00";
      // payload = [new Date(2020, 1, 1).toISOString(), new Date().toISOString()].join(",");
      state.daterange = payload;
    },
    SET_HOVERED_ALTITUDE (state, payload) {
      state.hovered_altitude = payload;
    },
    SET_WEATHER (state, payload) {
      state.wether_info = payload;
    },
    SET_MAP_TYPE (state, payload) {
      state.map_type = payload;
    },
    SET_MAP_CENTER (state, payload) {
      state.map_center = payload;
    },
    SET_MRSUS (state, payload) {
      state.departments = payload;
    },
    CLEAR_TRAKING (state) {
      state.traking_appliances = [];
    },
    ADD_TRAKING (state, id) {
      state.traking_appliances.push(id);
    },
    REMOVE_TRAKING (state, id) {
      state.traking_appliances = state.traking_appliances.filter(x => {
        return x != id;
      });
    },
    SET_INFO (state, payload) {
      state.selected_unit_info = payload;
    },
    CLEAR_TAILS (state) {
      state.appliances_tails = [];
    },
    ADD_TAIL (state, id) {
      state.appliances_tails.push(id);
    },
    REMOVE_TAIL (state, id) {
      state.appliances_tails = state.appliances_tails.filter(x => {
        return x != id;
      });
    },
    SET_POINTS (state, points) {
      state.points_on_map = points;
    },
    SET_TAILS_POINTS (state, points) {
      state.appliances_tails_points = points;
    },
    SET_LAST_POINT_INFO (state, id) {
      state.lst_point_info_id = id;
    },

    toggleStaticTypeId (state, id) {
      const ids = state.selectedStaticTypeIds;
      if (!ids.includes(id)) ids.push(id);
      else state.selectedStaticTypeIds = ids.filter(i => i !== id);
    },
  },

  actions: {
    toggleStaticTypeId ({ commit }, id) {
      commit("toggleStaticTypeId", id);
    },

    updateStatic ({ commit }, payload) {
      commit("SET_STATIC", payload);
    },
    daterange ({ commit }, payload) {
      commit("SET_DATERANGE", payload);
    },
    map_center ({ commit }, payload) {
      commit("SET_MAP_CENTER", payload);
    },
    setMapType ({ commit }, payload) {
      commit("SET_MAP_TYPE", payload);
    },
    wether_info ({ commit }, payload) {
      commit("SET_WEATHER", payload);
    },
    setMRSUSList ({ commit }, payload) {
      commit("SET_MRSUS", payload);
    },
    clearTracking ({ commit }) {
      commit("CLEAR_TRAKING");
    },
    addTracking ({ commit }, id) {
      commit("ADD_TRAKING", id);
    },
    removeTracking ({ commit }, id) {
      commit("REMOVE_TRAKING", id);
    },
    setInfo ({ commit }, payload) {
      commit("SET_INFO", payload);
    },
    clearTails ({ commit }) {
      commit("CLEAR_TAILS");
    },
    addTail ({ commit }, id) {
      commit("ADD_TAIL", id);
    },
    removeTail ({ commit }, id) {
      commit("REMOVE_TAIL", id);
    },
    setPoints ({ commit }, points) {
      commit("SET_POINTS", points);
    },
    setTailsPoints ({ commit }, points) {
      commit("SET_TAILS_POINTS", points);
    },
    hoverAltitude ({ commit }, payload) {
      commit("SET_HOVERED_ALTITUDE", payload);
    },
    setLastPointInfo ({ commit }, payload) {
      commit("SET_LAST_POINT_INFO", payload);
    },
  },
};
