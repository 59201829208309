import { User } from "@/models/User";
import storage from "@/utils/storage";
export let user = storage.get("user", User);
export const checkAuthorization = (user) => {
    return user instanceof User;
};
export const isAuthorized = () => checkAuthorization(user);
export const setUser = (userData) => {
    storage.set("user", userData);
    user = userData;
};
export const clearUser = () => {
    storage.remove("user");
    user = null;
};
