import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppLink",
    props: {
        name: { type: String, required: true },
        params: { type: Object, default: () => ({}) },
        newTab: { type: Boolean, default: false },
        hash: { type: String, default: "" },
    },
    setup(props, { attrs }) {
        const modifications = {};
        if (props.newTab) {
            modifications.target = "_blank";
        }
        const to = computed(() => ({
            name: props.name,
            params: props.params,
            hash: props.hash ? `#${props.hash}` : undefined,
        }));
        return {
            modifications,
            to,
            attrs,
        };
    },
});
