import { defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppList",
    inheritAttrs: false,
    props: {
        list: { type: Array, required: true },
        keyField: { type: String, default: "" },
        hideEmpty: { type: Boolean, default: false },
        listClass: { type: String, default: "" },
        itemClass: { type: String, default: "" },
        emptyText: { type: String, default: "" },
        emptyClass: { type: String, default: "" },
    },
});
