import FetchRequest from "../utils/request";
import authInterceptor from "@/services/api/auth-interceptor";
export const request = new FetchRequest({
    prefix: process.env.VUE_APP_API_NEW,
    headers: {
        "Content-Type": "application/json",
    },
    interceptors: [authInterceptor],
});
// interface ApiValidationErrorResponse<D extends Record<string, unknown>> {
//   error_code: null | string
//   errors: ApiValidationErrors<D>
//   message: string
// }
