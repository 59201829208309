import { defineComponent, computed } from "@vue/composition-api";
import { range } from "@/utils/number";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "AppPagination",
    inheritAttrs: false,
    props: {
        page: { type: Number, default: 1 },
        perPage: { type: Number, default: 20 },
        totalItems: { type: Number, default: 0 },
        limit: { type: Number, default: 7 },
        prevText: { type: String, default: "<" },
        nextText: { type: String, default: ">" },
        ellipsisText: { type: String, default: "…" },
        disabled: { type: Boolean, default: false },
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const MIN_TOTAL_PAGES_TO_COLLAPSE = 4;
        const totalPages = computed(() => {
            return Math.ceil(props.totalItems / props.perPage);
        });
        const maxAdjacentPages = computed(() => {
            return Math.max(Math.ceil((props.limit - 1) / 2), 0);
        });
        const shouldCollapseLeftSide = computed(() => {
            // Magic const 2: prevents collapsing a single page on the left side
            const diff = props.page - maxAdjacentPages.value;
            return (diff >= maxAdjacentPages.value &&
                diff > 2 &&
                totalPages.value > MIN_TOTAL_PAGES_TO_COLLAPSE);
        });
        const shouldCollapseRightSide = computed(() => {
            // Magic const 2: prevents collapsing a single page on the right side
            const diff = totalPages.value - 2 - props.page;
            return (diff > maxAdjacentPages.value &&
                totalPages.value > MIN_TOTAL_PAGES_TO_COLLAPSE);
        });
        const prevPageIsDisabled = computed(() => {
            return pageIsDisabled(props.page - 1);
        });
        const nextPageIsDisabled = computed(() => {
            return pageIsDisabled(props.page + 1);
        });
        function pageIsDisabled(page) {
            return props.disabled || page < 1 || page > totalPages.value;
        }
        const isVisible = computed(() => {
            return totalPages.value > 1;
        });
        const visibleItems = computed(() => {
            let items = [];
            let firstPage = shouldCollapseLeftSide.value
                ? props.page - maxAdjacentPages.value
                : 1;
            // If we're on last page, show at least one page to the left
            firstPage = Math.min(firstPage, totalPages.value - 1);
            let lastPage = shouldCollapseRightSide.value
                ? props.page + maxAdjacentPages.value
                : totalPages.value;
            // If we're on first page, show at least one page to the right
            lastPage = Math.max(lastPage, 2);
            // Default numbered items
            items = range(firstPage, lastPage, 1, true).map(page => getPageItem(page));
            if (shouldCollapseLeftSide.value) {
                items.unshift(getPageItem(1), getEllipsisItem("left"));
            }
            if (shouldCollapseRightSide.value) {
                items.push(getEllipsisItem("right"), getPageItem(totalPages.value));
            }
            return items;
        });
        const getPageItem = (page) => {
            const isActivePage = page === props.page;
            const isDisabled = pageIsDisabled(page);
            const itemProps = {
                disabled: isDisabled,
                active: isActivePage,
            };
            return {
                key: `page_${page}`,
                value: page.toString(),
                classes: itemProps,
                ...itemProps,
            };
        };
        const getEllipsisItem = (side) => {
            return {
                value: props.ellipsisText,
                key: `ellipsis_${side}`,
                classes: {
                    disabled: true,
                },
                disabled: true,
            };
        };
        const handleClick = (pageTmpl) => {
            const page = parseInt(pageTmpl);
            if (!pageIsDisabled(page))
                emit("change", page);
        };
        return {
            isVisible,
            visibleItems,
            prevPageIsDisabled,
            nextPageIsDisabled,
            handleClick,
        };
    },
});
