/* eslint-disable @typescript-eslint/no-unused-vars */
import { isNumber, isObject, isString } from "./check-types-runtime";
export const isDuplicatesExists = (list) => list.length !== new Set(list).size;
export const isListComplex = (list) => {
    return list.every(item => isObject(item));
};
export const isListSimple = (list) => {
    return list.every(item => isString(item) || isNumber(item));
};
export const isAllListItemsHasKey = (list, key) => {
    return list.every(i => key in i);
};
export const toggleItem = (list, item, keyField) => {
    let index;
    const newList = [...list];
    console.log({ list, item, keyField });
    if (isObject(item)) {
        const itemStr = JSON.stringify(item, null, 2);
        if (keyField === undefined) {
            throw new Error(`KEY FIELD IS REQUIRED IN ITEM: ${itemStr}`);
        }
        else if (!(keyField in item)) {
            throw new Error(`KEY FIELD: ${keyField.toString()} IS NOT EXIST IN ITEM: ${itemStr}`);
        }
    }
    if (isNumber(item) || isString(item)) {
        const itemStr = item.toString();
        if (keyField !== undefined) {
            throw new Error(`KEY FIELD PROVIDED: ${keyField.toString()}, BUT ITEM IS NOT OBJECT: ${itemStr}`);
        }
    }
    if (isListSimple(list) && !isObject(item)) {
        index = list.indexOf(item);
    }
    if (isListComplex(list) && isObject(item) && keyField !== undefined) {
        index = list.findIndex(i => i[keyField] === item[keyField]);
    }
    if (isNumber(index)) {
        if (index === -1)
            newList.push(item);
        else
            newList.splice(index, 1);
    }
    return newList;
};
export const searchInListByQuery = (list, query) => {
    query = query.toLowerCase().trim();
    if (isListSimple(list)) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return list.filter(item => item.toString().includes(query));
    }
    else if (isListComplex(list)) {
        return list.filter(item => {
            return Object
                .values(item)
                .filter(v => isString(v) || isNumber(v))
                .map(v => v.toString().toLowerCase())
                .some(v => v.includes(query));
        });
    }
    else
        throw new Error("ALL MEMBERS OF LIST SHOULD BE EITHER SIMPLE OR COMPLEX");
};
