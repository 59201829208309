
<template>
  <svg
    focusable="false"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <!-- eslint-disable-next-line max-len -->
    <path d="M14 8c0-2.21-1.79-4-4-4S6 5.79 6 8s1.79 4 4 4s4-1.79 4-4zM2 18v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4s-8 1.34-8 4zm16-8h4c.55 0 1 .45 1 1s-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: "AppIconRemovePerson",
};
</script>
