import Vue from "vue";
import Vuex from "vuex";

import { map } from "./modules/map";
import { management } from "./modules/management";
import department from "./modules/department";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    ...map.state,
    ...management.state,
  },
  actions: {
    ...map.actions,
    ...management.actions,
  },
  mutations: {
    ...map.mutations,
    ...management.mutations,
  },
  strict: debug,
  modules: {
    department,
  },
});
