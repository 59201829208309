import { defineComponent, computed } from "@vue/composition-api";
import { useEmitter } from "../../composable/emit";
export default defineComponent({
    name: "AppNumber",
    props: {
        value: { type: Number, default: 0 },
        placeholder: { type: String, default: "" },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const empty = computed(() => {
            if (props.disabled)
                return false;
            return props.required && props.value === 0;
        });
        const blur = (target) => {
            target.blur();
        };
        const input = (value) => {
            emit("input", parseFloat(value));
        };
        const change = (value) => {
            emit("change", parseFloat(value));
        };
        return {
            empty,
            blur,
            input,
            change,
        };
    },
});
