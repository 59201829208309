import Vue from "vue";

import AppButton from "../components/global/Button";
import AppCollapse from "../components/global/Collapse";
import AppSelect from "../components/global/Select";
import AppText from "../components/global/Text";
import AppNumber from "../components/global/Number";
import AppRadio from "../components/global/Radio";
import AppRadioGroup from "../components/global/RadioGroup";
import AppSelectableList from "../components/global/SelectableList";
import AppLink from "../components/global/Link";
import AppAutocomplete from "../components/global/Autocomplete";
import AppDate from "../components/global/Date";
import AppLoader from "../components/global/Loader";
import AppSuspense from "../components/global/Suspense";
import AppPagination from "../components/global/Pagination";
import AppList from "../components/global/List";
import AppIcon from "../components/global/Icon";
import AppSecureText from "../components/global/SecureText";
import AppCoordinate from "../components/global/Coordinate";

Vue.component("AppButton", AppButton);
Vue.component("AppCollapse", AppCollapse);
Vue.component("AppSelect", AppSelect);
Vue.component("AppText", AppText);
Vue.component("AppNumber", AppNumber);
Vue.component("AppRadio", AppRadio);
Vue.component("AppRadioGroup", AppRadioGroup);
Vue.component("AppSelectableList", AppSelectableList);
Vue.component("AppLink", AppLink);
Vue.component("AppAutocomplete", AppAutocomplete);
Vue.component("AppDate", AppDate);
Vue.component("AppLoader", AppLoader);
Vue.component("AppSuspense", AppSuspense);
Vue.component("AppPagination", AppPagination);
Vue.component("AppList", AppList);
Vue.component("AppIcon", AppIcon);
Vue.component("AppSecureText", AppSecureText);
Vue.component("AppCoordinate", AppCoordinate);
