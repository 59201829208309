/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    appliancesPoints: [],
    visibleApplianceIds: [],
    selectedApplianceIds: [],
    centeredApplianceId: -1,
  },

  getters: {
    visibleAppliancesPoints (state) {
      return state.appliancesPoints.filter(p => state.visibleApplianceIds.includes(p.appliance.id));
    },

    passiveAppliancesPoints (state, getters) {
      return getters.visibleAppliancesPoints
        .filter(p => !state.selectedApplianceIds.includes(p.appliance.id));
    },
    activeAppliancesPoints (state, getters) {
      return getters.visibleAppliancesPoints
        .filter(p => state.selectedApplianceIds.includes(p.appliance.id));
    },
  },

  mutations: {
    setAppliancesPoints (state, points) {
      state.appliancesPoints = points;
    },

    setVisibleApplianceIds (state, ids) {
      state.visibleApplianceIds = ids;
    },

    toggleApplianceId (state, id) {
      const ids = state.selectedApplianceIds;
      if (!ids.includes(id)) ids.push(id);
      else state.selectedApplianceIds = ids.filter(i => i !== id);
    },

    setSelectedApplianceIds (state, ids) {
      state.selectedApplianceIds = ids;
    },
    deselectApplianceIds (state, ids) {
      state.selectedApplianceIds = state.selectedApplianceIds.filter(id => !ids.includes(id));
    },

    setCenteredApplianceId (state, id) {
      state.centeredApplianceId = id;
    },
  },

  actions: {
    setAppliancesPoints ({ commit }, points) {
      commit("setAppliancesPoints", points);
    },

    addVisibleApplianceIds ({ state, dispatch }, ids) {
      const newVisibleApplianceIds = [...new Set([...state.visibleApplianceIds, ...ids])];
      if (newVisibleApplianceIds.length === state.visibleApplianceIds) return;

      dispatch("setVisibleApplianceIds", newVisibleApplianceIds);
    },
    setVisibleApplianceIds ({ commit }, ids) {
      commit("setVisibleApplianceIds", ids);
    },

    toggleApplianceId ({ commit }, id) {
      commit("toggleApplianceId", id);
    },
    selectApplianceId ({ state, commit }, id) {
      if (state.selectedApplianceIds.includes(id)) return;

      commit("toggleApplianceId", id);
    },

    setCenteredApplianceId ({ commit }, id) {
      commit("setCenteredApplianceId", id);
    },

    clearAllSelectedApplianceIds ({ commit }) {
      commit("setSelectedApplianceIds", []);
    },

    deselectApplianceIds ({ state, commit, dispatch }, ids) {
      commit("deselectApplianceIds", ids);
      const newVisibleApplianceIds = state.visibleApplianceIds.filter(id => !ids.includes(id));
      dispatch("setVisibleApplianceIds", newVisibleApplianceIds);
    },
  },
};
