import { defineComponent } from "@vue/composition-api";
import Check from "./icons/Check.vue";
import Link from "./icons/Link.vue";
import Loader from "./icons/Loader.vue";
import PlusCircle from "./icons/PlusCircle.vue";
import RemovePerson from "./icons/RemovePerson.vue";
import Trash from "./icons/Trash.vue";
import Triangle from "./icons/Triangle.vue";
import InfoRounded from "./icons/InfoRounded.vue";
import ClipboardArrow from "./icons/ClipboardArrow.vue";
import Eye from "./icons/Eye.vue";
import Bell from "./icons/Bell.vue";
export default defineComponent({
    name: "AppIcon",
    props: {
        name: { type: String, required: true },
        title: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        large: { type: Boolean, default: false },
    },
    emits: {
        click: null,
    },
    setup(props, { emit }) {
        const emitClick = (event) => {
            if (props.disabled)
                return;
            emit("click", event);
        };
        let component;
        if (props.name === "check")
            component = Check;
        if (props.name === "link")
            component = Link;
        if (props.name === "loader")
            component = Loader;
        if (props.name === "plus-circle")
            component = PlusCircle;
        if (props.name === "remove-person")
            component = RemovePerson;
        if (props.name === "trash")
            component = Trash;
        if (props.name === "triangle")
            component = Triangle;
        if (props.name === "info-rounded")
            component = InfoRounded;
        if (props.name === "clipboard-arrow")
            component = ClipboardArrow;
        if (props.name === "eye")
            component = Eye;
        if (props.name === "bell")
            component = Bell;
        return {
            emitClick,
            component,
        };
    },
});
