
<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z" />
  </svg>
</template>

<script>
export default {
  name: "AppIconPlusCircle",
};
</script>
