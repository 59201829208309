import Vue from "vue";
import VueRouter from "vue-router";

import { isAuthorized } from "@/store/user.ts";

Vue.use(VueRouter);

const beforeEnterAuthorized = (to, from, next) => {
  if (isAuthorized()) next({ name: "map" });
  else next();
};

const beforeEnterUnauthorized = (to, from, next) => {
  if (!isAuthorized()) next({ name: "login" });
  else next();
};

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "home",
    redirect: "/map",
    beforeEnter: beforeEnterUnauthorized,
  },
  {
    path: "/map",
    name: "map",
    component: () => import("./views/Map.vue"),
    beforeEnter: beforeEnterUnauthorized,
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("./modules/auth/AuthModule.vue"),
    beforeEnter: beforeEnterAuthorized,
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("./modules/auth/views/Login/index.vue"),
      },
    ],
  },
  {
    name: "events",
    path: "/events",
    component: () => import("./modules/events/EventModule.vue"),
    beforeEnter: beforeEnterUnauthorized,
    children: [
      {
        name: "create-event",
        path: "create",
        component: () => import("./modules/events/views/CreateEvent/index.vue"),
      },
      {
        name: "activate-event",
        path: "activate/:id",
        component: () => import("./modules/events/views/ActivateEvent/index.vue"),
      },
      {
        name: "active-events",
        path: "active",
        component: () => import("./modules/events/views/ActiveEvents/index.vue"),
        children: [{
          name: "active-event",
          path: ":id",
          component: () => import("./modules/events/views/ViewEvent/index.vue"),
        }],
      },
      {
        name: "new-events",
        path: "new",
        component: () => import("./modules/events/views/NewEvents/index.vue")
      },
      {
        name: "closed-events",
        path: "closed",
        component: () => import("./modules/events/views/ClosedEvents/index.vue"),
        children: [{
          name: "closed-event",
          path: ":id",
          component: () => import("./modules/events/views/ViewEvent/index.vue"),
        }],
      },
      {
        name: "canceled-events",
        path: "canceled",
        component: () => import("./modules/events/views/CanceledEvents/index.vue"),
        children: [{
          name: "canceled-event",
          path: ":id",
          component: () => import("./modules/events/views/ViewEvent/index.vue"),
        }],
      },
    ],
  },
  {
    path: "/playground",
    name: "playground",
    component: () => import("./components/common/Playground.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
