import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppCoordinate",
    inheritAttrs: false,
    props: {
        coordinate: {
            type: Object,
            default: () => ({ latitude: 0, longitude: 0 }),
        },
        latitude: {
            type: Number,
            default: 0,
        },
        longitude: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        const coordinate = computed(() => {
            if (props.coordinate.latitude && props.coordinate.longitude) {
                return props.coordinate;
            }
            else if (props.latitude && props.longitude) {
                const { latitude, longitude } = props;
                return { latitude, longitude };
            }
            else {
                throw new Error("COORDINATES IS REQUIRED");
            }
        });
        const coordinateHash = computed(() => {
            return `${coordinate.value.latitude},${coordinate.value.longitude}`;
        });
        return {
            coordinateHash,
        };
    },
});
