import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import Toast from "vue-toastification";
import VueCompositionAPI from "@vue/composition-api";

import "vue-toastification/dist/index.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import "./plugins/directive";
import "./plugins/global-components";

Vue.use(VueCompositionAPI);
Vue.use(Toast);

Vue.prototype.$axios = axios;
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API;

Vue.prototype.$windowSizes = function () {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y,
  };
};

Vue.config.productionTip = false;

Vue.filter("formatDate", (value) => {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY HH:mm");
  }
});
Vue.filter("formatPhone", (value) => {
  if (value) {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
});
Vue.filter("toFixed2", (value) => {
  if (value) {
    return parseFloat(value).toFixed(2);
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
