import { defineComponent, ref, watch } from "@vue/composition-api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/uk";
import { useEmitter } from "../../composable/emit";
export default defineComponent({
    name: "AppDate",
    components: {
        DatePicker,
    },
    emits: {
        change: (date) => !!date,
        input: (date) => !!date,
        clear: () => true,
    },
    props: {
        type: { type: String, default: "datetime" },
        format: { type: String, default: "DD.MM.YYYY HH:mm" },
        placeholder: { type: String, default: "" },
        value: { type: Date, default: () => new Date() },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const currentValue = ref(props.value);
        const updateValue = (date) => {
            if (props.readonly)
                return;
            currentValue.value = date;
            emit("input", currentValue.value);
        };
        const close = () => {
            if (props.readonly)
                return;
            if (currentValue.value !== null)
                emit("change", currentValue.value);
        };
        watch(() => props.value, date => { currentValue.value = date; });
        return {
            currentValue,
            updateValue,
            close,
        };
    },
});
