import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "AppButton",
    inheritAttrs: false,
    props: {
        disabled: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        title: { type: String, default: "" },
        styleBorder: { type: Boolean, default: false },
        styleColor: { type: Boolean, default: false },
        styleBackground: { type: Boolean, default: false },
        styleBold: { type: Boolean, default: false },
    },
    setup(props) {
        const styleClasses = computed(() => ({
            "style-border": props.styleBorder,
            "style-color": props.styleColor,
            "style-background": props.styleBackground,
            "style-bold": props.styleBold,
        }));
        return {
            styleClasses,
        };
    },
});
