import { computed, defineComponent, toRefs } from "@vue/composition-api";
import { isListComplex, isListSimple, isDuplicatesExists, isAllListItemsHasKey } from "../../utils/list";
export default defineComponent({
    name: "AppSelectableList",
    inheritAttrs: false,
    props: {
        list: { type: Array, required: true },
        keyField: { type: String, default: "" },
        // TODO: drop this prop
        titleField: { type: String, default: "" },
        // eslint-disable-next-line vue/require-default-prop
        selectedItemValue: { type: [String, Number] },
        // eslint-disable-next-line vue/require-default-prop
        selectedItemValues: { type: Array },
    },
    emits: {
        select: (item) => !!item,
    },
    setup(props, { emit }) {
        const { list, keyField, titleField, selectedItemValue, selectedItemValues } = toRefs(props);
        if (isListComplex(list.value)) {
            if (!isAllListItemsHasKey(list.value, keyField.value)) {
                throw new Error("SOME ELEMENT DOES NOT HAVE PROVIDED KEY FIELD");
            }
            // isAllListItemsHasKey ensure us that all items have "keyField" so we can cast string[]
            const keyFields = list.value.map(i => i[keyField.value]);
            if (isDuplicatesExists(keyFields))
                throw new Error("DUPLICATE VALUES");
        }
        if (isListSimple(list.value) && isDuplicatesExists(list.value)) {
            throw new Error("DUPLICATE VALUES");
        }
        const adaptedList = computed(() => {
            if (isListSimple(list.value)) {
                return list.value.map(item => ({
                    value: item,
                    title: item.toString(),
                }));
            }
            else if (isListComplex(list.value)) {
                if (keyField.value === "")
                    throw new Error("KEY FIELD NOT PROVIDED");
                // isAllListItemsHasKey have been made previously so we can cast AdaptedItem[]
                return list.value.map(item => ({
                    value: item[keyField.value],
                    title: item[titleField.value !== "" ? titleField.value : keyField.value],
                }));
            }
            else
                throw new Error("NOW SUPPORTED LIST TYPE");
        });
        const getItemSelectedStatus = (item) => {
            if ((selectedItemValues === null || selectedItemValues === void 0 ? void 0 : selectedItemValues.value) === undefined && (selectedItemValue === null || selectedItemValue === void 0 ? void 0 : selectedItemValue.value) === undefined) {
                throw new Error("NONE SELECTED VALUES PROVIDED");
            }
            if ((selectedItemValues === null || selectedItemValues === void 0 ? void 0 : selectedItemValues.value) !== undefined && selectedItemValues.value.length > 0) {
                return selectedItemValues.value.includes(item.value);
            }
            if ((selectedItemValue === null || selectedItemValue === void 0 ? void 0 : selectedItemValue.value) !== undefined && selectedItemValue.value !== "") {
                return selectedItemValue.value === item.value;
            }
            return false;
        };
        const findItem = (adaptedItem) => {
            let item;
            if (isListSimple(list.value)) {
                item = list.value[Number(adaptedItem.value)];
            }
            if (isListComplex(list.value)) {
                item = list.value.find(i => i[keyField.value] === adaptedItem.value);
            }
            return item;
        };
        const selectItem = (adaptedItem) => {
            const item = findItem(adaptedItem);
            console.log("🚀 ~ selectItem ~ item", item);
            emit("select", item);
        };
        return {
            adaptedList,
            findItem,
            getItemSelectedStatus,
            selectItem,
        };
    },
});
