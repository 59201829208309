export class User {
    constructor(user) {
        var _a, _b, _c;
        this.id = user.id;
        this.username = user.username;
        this.firstName = (_a = user.first_name) !== null && _a !== void 0 ? _a : "";
        this.lastName = (_b = user.last_name) !== null && _b !== void 0 ? _b : "";
        this.middleName = (_c = user.middle_name) !== null && _c !== void 0 ? _c : "";
        this.fullName = getApiUserFullName(user);
        this.email = user.email;
        this.phone = user.phone;
        this.linkedPhone = user.phone_secondary;
        this.photo = user.photo;
        this.role = user.role;
        this.dateBirth = user.dob === null ? null : new Date(user.dob);
        this.address = user.address;
        this.post = user.position;
        this.additionalInfo = user.additional_info;
        this.created = new Date(user.created);
        this.updated = new Date(user.updated);
        this.is = {
            active: user.is_active,
            verified: user.is_verified,
        };
    }
}
function getApiUserFullName(user) {
    const firstName = user.first_name === null ? "" : ` ${user.first_name}`;
    const lastName = user.last_name === null ? "" : ` ${user.last_name}`;
    const middleName = user.middle_name === null ? "" : ` ${user.middle_name}`;
    return `${lastName}${firstName}${middleName}`.trim();
}
