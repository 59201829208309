<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { initializeAccessToken } from "@/store/access-token";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  beforeCreate () {
    initializeAccessToken();
  },
};
</script>

<style lang="scss">
@import "~@/styles/global";
@import "~@/styles/global-vars";
</style>
