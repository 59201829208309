import { computed, defineComponent, ref, toRefs } from "@vue/composition-api";
import TriangleIcon from "./icons/Triangle.vue";
export default defineComponent({
    name: "AppCollapse",
    inheritAttrs: false,
    components: {
        TriangleIcon,
    },
    props: {
        initiallyClosed: { type: Boolean, default: false },
        forceOpen: { type: Boolean, default: false },
        direction: {
            type: String,
            default: "column",
        },
        ratio: {
            type: Array,
            default: () => [3, 7],
        },
        titleClass: { type: [String, Object, Array], default: "" },
        contentClass: { type: [String, Object, Array], default: "" },
    },
    setup(props) {
        const { forceOpen, ratio } = toRefs(props);
        const collapsedStatus = ref(props.initiallyClosed);
        const collapsed = computed(() => {
            if (forceOpen.value === true)
                return false;
            return collapsedStatus.value;
        });
        const styleVars = computed(() => {
            const [title, content] = ratio.value;
            const sum = title + content;
            return {
                "--app-collapse-title-ratio": title / sum,
                "--app-collapse-content-ratio": content / sum,
            };
        });
        function collapse() {
            collapsedStatus.value = !collapsedStatus.value;
        }
        function open() {
            collapsedStatus.value = false;
        }
        function close() {
            collapsedStatus.value = true;
        }
        return {
            collapsed,
            styleVars,
            collapse,
            open,
            close,
        };
    },
});
