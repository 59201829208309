import merge from "deepmerge";
import { ServerError } from "../models/ApiError";
import { fail, success } from "./either";
import params2query from "./params-to-query";
export default class FetchRequest {
    constructor(options = {}) {
        this.defaultOptions = {
            prefix: "",
            headers: {},
            params: {},
            interceptors: [],
        };
        this.options = merge(this.defaultOptions, options);
    }
    generateFinalUrl(url, options = {}) {
        var _a, _b;
        const prefix = (_a = options.prefix) !== null && _a !== void 0 ? _a : this.options.prefix;
        const params = merge(this.options.params, (_b = options.params) !== null && _b !== void 0 ? _b : {});
        let finalUrl = `${prefix}${url}`;
        if (Object.keys(params).length > 0)
            finalUrl += `?${params2query(params)}`;
        return finalUrl;
    }
    generateFinalHeaders(options = {}) {
        var _a;
        return merge(this.options.headers, (_a = options.headers) !== null && _a !== void 0 ? _a : {});
    }
    async runInterceptors(response) {
        for await (const interceptor of this.options.interceptors) {
            await interceptor(response);
        }
    }
    async handleResponse(response) {
        await this.runInterceptors(response);
        if (response.ok) {
            if (response.status === 204)
                return success(null);
            return response.json().then(json => success(json));
        }
        return fail(new ServerError(response));
    }
    async handleCorrectResponse(response) {
        await this.runInterceptors(response);
        if (response.ok) {
            if (response.status === 204)
                return null;
            return response.json();
        }
        const error = new ServerError(response);
        await error.print(false);
        throw error;
    }
    runFetch({ method, url, data, options }) {
        const finalUrl = this.generateFinalUrl(url, options);
        const headers = this.generateFinalHeaders(options);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fetchOptions = { method, headers };
        if (data !== undefined)
            fetchOptions.body = JSON.stringify(data);
        return fetch(finalUrl, fetchOptions);
    }
    runSafeFetch(method, url, options) {
        return this.runFetch({ method, url, options });
    }
    runUnsafeFetch(method, url, data, options) {
        return this.runFetch({ method, url, options, data });
    }
    get(url, options) {
        return this.runSafeFetch("GET", url, options).then(r => this.handleCorrectResponse(r));
    }
    checkableGet(url, options) {
        return this.runSafeFetch("GET", url, options).then(r => this.handleResponse(r));
    }
    post(url, data, options) {
        return this.runUnsafeFetch("POST", url, data, options).then(r => this.handleCorrectResponse(r));
    }
    checkablePost(url, data, options) {
        return this.runUnsafeFetch("POST", url, data, options).then(r => this.handleResponse(r));
    }
    delete(url, options) {
        return this.runSafeFetch("DELETE", url, options).then(r => this.handleCorrectResponse(r));
    }
    checkableDelete(url, options) {
        return this.runSafeFetch("DELETE", url, options).then(r => this.handleResponse(r));
    }
    put(url, data, options) {
        return this.runUnsafeFetch("PUT", url, data, options).then(r => this.handleCorrectResponse(r));
    }
    checkablePut(url, data, options) {
        return this.runUnsafeFetch("PUT", url, data, options).then(r => this.handleResponse(r));
    }
    patch(url, data, options) {
        return this.runUnsafeFetch("PATCH", url, data, options).then(r => this.handleCorrectResponse(r));
    }
    checkablePatch(url, data, options) {
        return this.runUnsafeFetch("PATCH", url, data, options).then(r => this.handleResponse(r));
    }
    file(url, filename, options) {
        return this.runSafeFetch("GET", url, options)
            .then(response => response.blob())
            .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
    }
    setAuthorizationHeader(token) {
        if (token !== "")
            this.options.headers.Authorization = `Token ${token}`;
    }
    deleteAuthorizationHeader() {
        var _a, _b;
        (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? true : delete _b.Authorization;
    }
}
