import { computed, defineComponent, ref } from "@vue/composition-api";
import { isString } from "@/utils/check-types-runtime";
export default defineComponent({
    name: "AppSecureText",
    inheritAttrs: false,
    props: {
        value: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
    },
    emits: {
        input: (str) => isString(str),
        change: (str) => isString(str),
    },
    setup(props, { emit }) {
        const revealSecret = ref(false);
        const type = computed(() => revealSecret.value ? "text" : "password");
        const toggleReveal = () => {
            revealSecret.value = !revealSecret.value;
        };
        const empty = computed(() => {
            if (props.disabled)
                return false;
            return props.required && props.value === "";
        });
        const focused = ref(false);
        const focus = (target) => {
            focused.value = true;
            target.select();
        };
        const blur = (target) => {
            focused.value = false;
            target.blur();
        };
        const input = (value) => {
            emit("input", value);
        };
        const change = (value) => {
            emit("change", value);
        };
        return {
            type,
            revealSecret,
            toggleReveal,
            empty,
            focused,
            focus,
            blur,
            input,
            change,
        };
    },
});
