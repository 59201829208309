import { getType, isString, isObject, isArray } from "../utils/check-types-runtime";
const insertObject = (classes, arg) => (Object.entries(arg).reduce((cls, [name, value]) => value === true ? cls.concat(name) : cls, classes));
const insertString = (classes, arg) => classes.concat(arg);
const generateError = (arg) => (
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
new Error(`NOT SUPPORTED class name TYPE, class name: "${arg}", type of class name: "${getType(arg)}"`));
export const name = "module-class";
export const definition = (el, binding, vnode) => {
    const { value } = binding;
    const { context } = vnode;
    let classNames = [];
    if (isString(value))
        classNames = insertString(classNames, value);
    else if (isObject(value))
        classNames = insertObject(classNames, value);
    else if (isArray(value)) {
        value.forEach((className) => {
            if (isString(className))
                classNames = insertString(classNames, className);
            else if (isObject(className))
                classNames = insertObject(classNames, className);
            else
                throw generateError(className);
        });
    }
    else
        throw generateError(value);
    [...el.classList.values()].forEach(className => {
        el.classList.remove(className);
    });
    classNames.forEach(className => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const moduleClassName = context.$style[className];
        if (moduleClassName === undefined)
            throw new Error(`CLASS "${className}" NOT FOUND`);
        el.classList.add(moduleClassName);
    });
};
